import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Landing from "./pages/Landing"
import Portfolio from "./pages/Portfolio";
import Navigation from './components/Navigation';
import Header from "./components/Header";
import Footer from "./components/Footer";

function App() {
  return (<>
      <Navigation />
       <Router>
          <Routes>
            <Route exact path="/" element={<Landing />} />
            <Route exact path="/services" element={<Portfolio />} />
          </Routes>
      </Router>
    <Footer /></>  
  );
}

export default App;
