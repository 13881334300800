import React, {useState} from "react";
import ServiceCard from './ServiceCard'
import {services} from "../config/services";
import Portfolio from "../pages/Portfolio";

export default function Catalog()
{
  const [activeService, setActiveService] = useState(null);

  function handleServiceSelection(serviceid)
  {
    setActiveService(prevState => serviceid);
    console.log(activeService)
  };

  const serviceItems = services.categories.map(service =>
    <div className="col-lg-4 col-md-6 col-12 mt-4 pt-2" key={service.id}>
      <ServiceCard isActive={activeService} service={service} notifyParent={handleServiceSelection}/>
    </div>
  );

  return (
  <>
  <section className="page-section bg-light" id="services">
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-12">
        <div className="text-center">
          <h2 className="section-heading text-uppercase">Our Services</h2>
          <h3 className="section-subheading text-muted">Content that compliments your business.</h3>
        </div>
      </div>
    </div>
    <div className="row">
      {serviceItems}
    </div>
  </div>      
</section>
<section className={activeService?"portfolio d-block": "portfolio d-none"} ><Portfolio /></section>
</>
)};
