export const services = {
    "name":"DigitalQuills",
    "version":1.0,
    "categories":[
        {
            "name":"Digital Media Content Services",
            "short_description":"Engage your audience across digital content platforms.",
            "long_description":"",
            "image_url":"service3.jpg",
            "id":"DMCS",
            "capabilities":[
                {
                "id":"DMCS-01",
                "title":"Website Content",
                "description":"SEO-optimized website content that translates into more traffic to the website.",
                "tags":"",
                "image_url":""
            },
            {
                "id":"DMCS-02",
                "title":"Blogs & Articles",
                "description":"Technology and business focussed, well-researched, SEO-optimized blogs & articles that engage your potential and loyal customers",
                "tags":"",
                "image_url":""
            },
            {
                "id":"DMCS-03",
                "title":"Social Media Posts",
                "description":"Informative thought leadership and social media posts that reflect on matters relevant to you and your business.",
                "tags":"",
                "image_url":""
            },
            {
                "id":"DMCS-04",
                "title":"Chatbots & Virtual Assistant Script",
                "description":"Scripts for meaningful conversations between the users and the chatbot.",
                "tags":"",
                "image_url":""
            }
        ]
        },
        {
            "name":"Website Development",
            "short_description":"Enter the digital sphere with an impressive, seo-optimized web presence.",
            "long_description":"",
            "image_url":"service5.jpg",
            "id":"WPSD",
            "capabilities":[
                {
                "id":"WPSD-01",
                "title":"Static Websites & Landing Copy",
                "description":"Quick single-page / multi-page static websites with easy to navigate layout & crisp content",
                "tags":"",
                "image_url":""
            },
            {
                "id":"WPSD-02",
                "title":"Wordpress Development",
                "description":"Get started with your simple yet effective website using Wordpress. ",
                "tags":"",
                "image_url":""
            },
            {
                "id":"WPSD-03",
                "title":"Shopify Development",
                "description":"Get your services and products to your customers from your Shopify business portal.",
                "tags":"",
                "image_url":""
            },
            {
                "id":"WPSD-04",
                "title":"Product Descriptions",
                "description":"Quickly generate persuasive descriptions for your e-commerce products.",
                "tags":"",
                "image_url":""
            }
        ]
        },
        {
            "name":"CopyWriting Services",
            "short_description":"A copy that's effective, to-the-point and high on readability.",
            "long_description":"",
            "image_url":"service4.jpg",
            "id":"CWCS",
            "capabilities":[
                {
                "id":"CWCS-01",
                "title":"Email Copy",
                "description":"An inexpensive,convenient, and effective way to reach your target audience and drive some action.",
                "tags":"",
                "image_url":""
            },
            {
                "id":"CWCS-02",
                "title":"Ad Copy",
                "description":"Advertise effectively to attract your potential customers.",
                "tags":"",
                "image_url":""
            },
            {
                "id":"CWCS-03",
                "title":"Press Release Copy",
                "description":"Get the required exposure over media for your business.",
                "tags":"",
                "image_url":""
            },
            {
                "id":"CWCS-04",
                "title":"WhatsApp & SMS Copy",
                "description":"More personalized way to reach your customers.",
                "tags":"",
                "image_url":""
            }
        ]
        },
        {
            "name":"Linguistic Services",
            "short_description":"Let language be no bar when you look to expand your global presence.",
            "long_description":"",
            "image_url":"service2.jpg",
            "id":"LGCS",
            "capabilities":[
                {
                "id":"LGCS-01",
                "title":"Translation Services",
                "description":"Get your message across to a wide range of audience speaking different languages.",
                "tags":"",
                "image_url":""
            },
            {
                "id":"LGCS-02",
                "title":"Transcription Services",
                "description":"Speech to Text Services to engage in the language your potential customers speak.",
                "tags":"",
                "image_url":""
            }
        ]
        },
        {
            "name":"Print Media Services",
            "short_description":"Ink your impression with the print media.",
            "long_description":"",
            "image_url":"service1.jpg",
            "id":"PMCS",
            "capabilities":[
                {
                "id":"PMCS-01",
                "title":"Business Magazine Articles ",
                "description":"Share your business stories with the world.",
                "tags":"",
                "image_url":""
            },
            {
                "id":"PMCS-02",
                "title":"Flyers & Posters",
                "description":"Crisp and attractive print content to get your services across. ",
                "tags":"",
                "image_url":""
            },
            {
                "id":"PMCS-03",
                "title":"Standees Content",
                "description":"Reach out to the masses with strategic standees that deliver your message effectively.",
                "tags":"",
                "image_url":""
            }
        ]
        }
    ]

}