import React from 'react'

const About = () => {
    return ( 
  <section className="page-section bg-light text-center" id="about">
  <div className="container px-4 px-lg-5">
    <div className="row gx-4 gx-lg-5 justify-content-center">
      <div className="col-lg-12">
        <h2 className="section-heading text-uppercase">Who We Are</h2>
        <div className="row">
          <div className="col-lg-8 mx-auto text-left"><p className="large text-muted"> We believe that content is less about ornate words and trending images and more about voicing
              your authentic and honest thoughts when speaking to the customer.</p></div>
        </div>
        <div className="row">
          <div className="col-lg-8 mx-auto text-left"><p className="large text-muted">We believe in creating and delivering content, unique and relevant to your business.
              We are a group of experts (birds of the same feather) flocking together to deliver SEO-driven,
              plagiarism-free, and provable content that ups your business credibility.</p></div>
        </div>
      </div>
    </div>
    <img className="img-fluid" src="assets/img/ipad.png" alt="..." />
  </div>
</section> 
)};

export default About;