import React, { useState } from 'react'


const Contact = () => {

  const validate = (e) => {
    // e.preventDefault();
    // var URL = "https://abc1234.execute-api.us-east-1.amazonaws.com/01/contact";

         var Namere = /[A-Za-z]{1}[A-Za-z]/;
         if (!Namere.test(formData.name)) {
             return "Please provide your name ";
         }
         var mobilere = /[0-9]{10}/;
         if (!mobilere.test(formData.phone)) {;
             return "Please provide your 10 digit mobile number";
         }
         if (formData.email === "") {
             return "Please provide your email address";
         }

         var reeamil = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,6})?$/;
         if (!reeamil.test(formData.email)) {
             return "Please provide correct email address";
         }

         return "";
  };

const [formData, setFormData] = useState({'name':"", 'email':"", 'phone':"", 'message':"", 'validationerror':""})

const handleChange = (event) => {
  const value = event.target.value;
  const idname = event.target.id;
  setFormData({...formData, [idname]:value});
}


const handleSubmit = (event) => {
  event.preventDefault();
  const valerror = validate(event);
  if (valerror !== ""){
    // show message on screen to fix error in form
    setFormData({...formData, 'validationerror':valerror});
    return;
  }

  fetch('https://xjixmfg72kbvrx2qlgs5o6oede0nnguc.lambda-url.ap-south-1.on.aws/', {
         method: 'POST',
         body: JSON.stringify({
            name: formData.name,
            emailid: formData.email,
            phone: formData.phone,
            message: formData.message
         }),
         headers: {
            'Content-type': 'application/json; charset=UTF-8',
         },
      })
         .then((res) => res.json())
         .then(() => {
          alert('Thanks for your message. We will reach out to you shortly')
          setFormData({...formData,'validationerror':""});
         })
         .catch((err) => {
            console.log(err.message);
         });
   };

 return (
  <section className="page-section" id="contact">
  <div className="container">
    <div className="text-center">
      <h2 className="section-heading text-uppercase">Contact Us</h2>
      <h3 className="section-subheading text-white-50">Reach out to us for more information about our services</h3>
    </div>
    <form id="contactForm" onSubmit={handleSubmit}>
      <div className="row align-items-stretch mb-5">
        <div className="col-md-6">
          <div className="form-group">
            {/* Name input*/}
            <input className="form-control" id="name" value={formData.name} type="text" placeholder="Your Name *" required onChange={handleChange} />
          </div>
          <div className="form-group">
            {/* Email address input*/}
            <input className="form-control" id="email" value={formData.email} type="email" placeholder="Your Email *" required onChange={handleChange} />
          </div>
          <div className="form-group mb-md-0">
            {/* Phone number input*/}
            <input className="form-control" id="phone" value={formData.phone} type="tel" placeholder="Your Phone *" required onChange={handleChange} />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group form-group-textarea mb-md-0">
            {/* Message input*/}
            <textarea className="form-control" id="message" value={formData.message} placeholder="Your Message *" required  onChange={handleChange}/>
          </div>
        </div>
      </div>
      <div id="submitErrorMessage"><div className="text-center text-danger mb-3">{formData.validationerror}</div></div>
      {/* Submit Button*/}
      <div className="text-center"><button className="btn btn-primary btn-xl text-uppercase" id="submitButton" type="submit">Send Message</button></div>
    </form>
  </div>
</section>
    );


};

export default Contact