import React from 'react'
import ServiceItemCard from './ServiceItemCard'

export default function ServiceCard({isActive, service, notifyParent}) {
    return ( <>
        <div className="card service-wrapper rounded border-0 shadow p-4">
        <div className="icon text-center text-custom h1 shadow rounded bg-white">
          <span className="uim-svg" style={{}}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="1em"><rect width={20} height={15} x={2} y={3} className="uim-tertiary" rx={3} /><path className="uim-primary" d="M16,21H8a.99992.99992,0,0,1-.832-1.55469l4-6a1.03785,1.03785,0,0,1,1.66406,0l4,6A.99992.99992,0,0,1,16,21Z" /></svg></span>
        </div>
        <div className="content mt-4">
          <h5 className="title">
            {service.name}
          </h5>
          <p className="text-muted mt-3 mb-0">
            {service.short_description}
          </p>
          <div className="mt-3">
           <a href={'#'+service.id} onClick={()=> notifyParent(service.id)} className="text-custom">Know More <i className="mdi mdi-chevron-right" /></a>
          </div>
        </div>
        <div className="big-icon h1 text-custom">
          <span className="uim-svg" style={{}}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="1em"><rect width={20} height={15} x={2} y={3} className="uim-tertiary" rx={3} /><path className="uim-primary" d="M16,21H8a.99992.99992,0,0,1-.832-1.55469l4-6a1.03785,1.03785,0,0,1,1.66406,0l4,6A.99992.99992,0,0,1,16,21Z" /></svg></span>
        </div>
      </div>
     
      </>
    );
}
