import React from 'react'
import * as Constants from '../config/constants.js';

const Banner = () => {
    return (
<div id="carouselExampleDark" className="carousel carousel-dark slide" data-bs-ride="carousel">
  <div className="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to={0} className="active" aria-current="true" aria-label="Slide 1" />
    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to={1} aria-label="Slide 2" />
    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to={2} aria-label="Slide 3" />
  </div>
  <div className="carousel-inner">
    <div className="carousel-item active" data-bs-interval={5000}>
      <img src="assets/img/banner/banner4.gif" className="d-block w-100" alt="..." />
    </div>
    <div className="carousel-item" data-bs-interval={5000}>
      <img src="assets/img/banner/banner2.jpg" className="d-block w-100" alt="..." />
      
    </div>
    <div className="carousel-item" data-bs-interval={5000}>
      <img src="assets/img/banner/banner3.jpg" className="d-block w-100" alt="..." />
    </div>
  </div>
   <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
    {/* <span className="carousel-control-prev-icon" aria-hidden="true" />
    <span className="visually-hidden">Previous</span> */}
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
    {/* <span className="carousel-control-next-icon" aria-hidden="true" />
    <span className="visually-hidden">Next</span> */}
  </button> 
</div>
);
};
export default Banner



