import React from 'react'

const Navigation = () => {
return (
<nav className="navbar navbar-light fixed-top navbar-expand-lg" id="mainNav">
  <div className="container">
   <a className="navbar-brand" href="#page-top">
   <img src="logo.png" className="d-block" alt="..." />
   </a> 
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
    <span className="navbar-toggler-icon"></span>
    </button>
    <div className="navbar-collapse collapse" id="navbarResponsive">
      <ul className="navbar-nav text-uppercase ms-auto py-4 py-lg-0">
        <li className="nav-item"><a className="nav-link" href="/">Home</a></li>
        <li className="nav-item"><a className="nav-link" href="/#services">Services</a></li>
        <li className="nav-item"><a className="nav-link" href="/#about">About</a></li>
        <li className="nav-item"><a className="nav-link" href="/#team">Team</a></li>
        <li className="nav-item"><a className="nav-link" href="/#contact">Contact</a></li>
      </ul>
    </div>
  </div>
</nav>

);
};

export default Navigation