import React from 'react'
import * as Constants from '../config/constants.js';
import Banner from './Banner'

const Header = () => {
    return (
<header className="masthead">
    <Banner />
</header>

);
};
export default Header