import React from 'react'

const Team = () => {
    return ( <section className="page-section bg-light" id="team">
  <div className="container">
    <div className="text-center">
      <h2 className="section-heading text-uppercase">Our Amazing Team</h2>
      <h3 className="section-subheading text-muted">Lorem ipsum dolor sit amet consectetur.</h3>
    </div>
    <div className="row">
      <div className="col-lg-6">
        <div className="team-member">
          <img className="mx-auto rounded-circle" src="assets/img/team/ceo-avatar.png" alt="..." />
          <h4>Sneha Sinha</h4>
          <p className="text-muted">Founder & Editor-In-Chief</p>
          <a className="btn btn-dark btn-social mx-2" href="#!" aria-label="Twitter Profile"><i className="fab fa-twitter" /></a>
          <a className="btn btn-dark btn-social mx-2" href="#!" aria-label="Facebook Profile"><i className="fab fa-facebook-f" /></a>
          <a className="btn btn-dark btn-social mx-2" href="#!" aria-label="LinkedIn Profile"><i className="fab fa-linkedin-in" /></a>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="team-member">
          <img className="mx-auto rounded-circle" src="assets/img/team/cto-avatar.png" alt="..." />
          <h4>Shivam Sinha</h4>
          <p className="text-muted">Founder & Technology Head</p>
          <a className="btn btn-dark btn-social mx-2" href="#!" aria-label="Twitter Profile"><i className="fab fa-twitter" /></a>
          <a className="btn btn-dark btn-social mx-2" href="#!" aria-label="Facebook Profile"><i className="fab fa-facebook-f" /></a>
          <a className="btn btn-dark btn-social mx-2" href="#!" aria-label="LinkedIn Profile"><i className="fab fa-linkedin-in" /></a>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-lg-8 mx-auto text-center"><p className="large text-muted">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aut eaque, laboriosam veritatis, quos non quis ad perspiciatis, totam corporis ea, alias ut unde.</p></div>
    </div>
  </div>
</section>
);
}

export default Team;