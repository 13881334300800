import React from 'react'

export function ServiceItemCard({offerings}){
  const serviceSubItems = offerings.map(offering => 
    <div className="col-md-6 mb-5">
    <div className="text-center" key={offering.id}>
    <i className="bi-phone icon-feature text-gradient d-block mb-3" />
    <h3 className="font-alt">{offering.title} </h3>
    <p className="text-muted mb-0">{offering.description}</p>
  </div> 
  </div>
  );

    return ( 
      <>
      <div className="container-fluid px-5">
      <div className="row gx-5">
        {serviceSubItems}
      </div>
      </div>

        </>
  
 );
}
     