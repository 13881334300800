import React from 'react'
import Header from "../components/Header"
import Navigation from "../components/Navigation"
import Catalog from "../components/Catalog"
import Contact from "../components/Contact"
import About from "../components/About"
import Team from "../components/Team"
import Portfolio from "./Portfolio"

const Landing = () => {
    return (<>
        <Header/>
        <Catalog />
        <About />
        <aside className="text-center bg-gradient-primary-to-secondary">
  <div className="container px-3">
    <div className="row gx-5 justify-content-center">
      <div className="col-xl-12">
        <div className="h2 fs-1 text-black mb-6">"Professional team & outcome focused delivery, by Digital Quills team for our website"</div>
        <img src="logo.png" alt="..." />
      </div>
    </div>
  </div>
</aside>
        <Team />
        <Contact />
        </>
)};

export default Landing