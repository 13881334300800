import React from 'react'
import {services} from "../config/services";
import {ServiceItemCard} from '../components/ServiceItemCard';

export default function Portfolio(){
 
const serviceItems = services.categories.map((service, index) =>
 index % 2 === 0 ? 
<div className="row g-0" id={service.id} key={service.id}>
  <div className="col-lg-4 order-lg-2" >
  <img src={'assets/img/portfolio/' + service.image_url} className="d-block w-100" alt="..." />
  </div>
  <div className="col-lg-8 order-lg-1 my-auto showcase-text">
    <ServiceItemCard offerings={service.capabilities} />
  </div>
</div>
:
<div className="row g-0" id={service.id} key={service.id}>
  <div className="col-lg-4 text-white">
  <img src={'assets/img/portfolio/' + service.image_url} className="d-block w-100" alt="..." />
  </div>
  <div className="col-lg-8 my-auto showcase-text">
    <ServiceItemCard offerings={service.capabilities} />
  </div>
</div>
);

return (
  <div className="container-fluid light p-0">
  {serviceItems}
  </div>    
)};







// <section class="showcase">

//     <div class="row g-0">
//         <div class="col-lg-6 order-lg-2 text-white showcase-img" style="background-image: url('assets/img/bg-showcase-1.jpg')"></div>
//         <div class="col-lg-6 order-lg-1 my-auto showcase-text">
//             <h2>Fully Responsive Design</h2>
//             <p class="lead mb-0">When you use a theme created by Start Bootstrap, you know that the theme will look great on any device, whether it's a phone, tablet, or desktop the page will behave responsively!</p>
//         </div>
//     </div>
//     <div class="row g-0">
//         <div class="col-lg-6 text-white showcase-img" style="background-image: url('assets/img/bg-showcase-2.jpg')"></div>
//         <div class="col-lg-6 my-auto showcase-text">
//             <h2>Updated For Bootstrap 5</h2>
//             <p class="lead mb-0">Newly improved, and full of great utility classes, Bootstrap 5 is leading the way in mobile responsive web development! All of the themes on Start Bootstrap are now using Bootstrap 5!</p>
//         </div>
//     </div>
//     <div class="row g-0">
//         <div class="col-lg-6 order-lg-2 text-white showcase-img" style="background-image: url('assets/img/bg-showcase-3.jpg')"></div>
//         <div class="col-lg-6 order-lg-1 my-auto showcase-text">
//             <h2>Easy to Use & Customize</h2>
//             <p class="lead mb-0">Landing Page is just HTML and CSS with a splash of SCSS for users who demand some deeper customization options. Out of the box, just add your content and images, and your new landing page will be ready to go!</p>
//         </div>
//     </div>
// </div>
// </section>

